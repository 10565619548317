:root {
  --grip-bg-color: #bba1e2;
  --pink-color: #cf7787;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap");

@import "~bootswatch/dist/pulse/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/pulse/bootswatch";

body {
  font-family: "Nunito", sans-serif;
  background: var(--bs-light);
}

.btn-success,
.btn-danger {
  color: white;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body,
#root {
  height: 100vh;
}

.form-control {
  background-color: $gray-100;
}
